"use client"

import React, { useEffect, useCallback } from "react"
import { useRouter, usePathname } from "next/navigation"
import clsx from "clsx"
import { MuiAppBar, Tab, useMediaQuery, useTheme, Tabs } from "../_common"
import { ITabBarProps } from "./types"
import { getLocationHashVal } from "../MyQuestico/VoucherInfo/utils"
import classes from "./TabsHeader.module.scss"

interface ITabsProps extends ITabBarProps {
  tabValue: string
  setTabValue: React.Dispatch<React.SetStateAction<string>>
  rootClassName?: string
  tabsClassName?: string
}

const TabsHeader: React.FC<ITabsProps> = ({
  items = [],
  tabsVariant,
  tabValue,
  setTabValue,
  rootClassName,
  tabClassName,
  tabsClassName,
  onTabChange,
  withHash = true,
}) => {
  const router = useRouter()
  const pathname = usePathname()
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down("md"))

  useEffect(() => {
    const hash = getLocationHashVal()
    const pageHashes = items.map((tabItem) => tabItem.value)

    if (hash && pageHashes.includes(hash)) {
      setTabValue(hash)
    }
  }, [items, pathname, setTabValue])

  const handleTabsChange = useCallback(
    (_e: React.ChangeEvent<unknown>, newValue: string) => {
      setTabValue(newValue)
      if (withHash) {
        router.replace(`${pathname}#${newValue}`, { scroll: false })
      }

      if (onTabChange) onTabChange(newValue)
    },
    [onTabChange, pathname, router, setTabValue, withHash]
  )

  return (
    <div className={rootClassName}>
      <MuiAppBar className={classes.appBar} component="div">
        <Tabs
          variant={tabsVariant}
          indicatorColor="primary"
          value={tabValue}
          textColor="primary"
          onChange={handleTabsChange}
          className={tabsClassName}
        >
          {items.map(({ label, value, shortLabel }) => (
            <Tab
              data-testid={`${value}-tab`}
              disabled={value === tabValue}
              key={value}
              className={clsx(classes.tab, tabClassName)}
              label={isMobile && shortLabel ? shortLabel : label}
              value={value}
            />
          ))}
        </Tabs>
      </MuiAppBar>
    </div>
  )
}

export default TabsHeader
